import React from "react";
import { connect } from "react-redux";
import * as Ant from "antd";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Theme from "../Theme";
import * as Catalog from "../Contexts/CatalogContext";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import categoryTreeRoot from "../productCategories";
import getCategoryFromLocation from "../Utils/getCategoryFromLocation";
// page specific components
import CategoryTree from "../Components/CategoryTree";
import CategoryMenuItem from "../Components/CategoryMenuItem";
import CategoryBreadcrumb from "../Components/CategoryBreadcrumb";
import FeatureBar from "../Components/FeatureBar";
import ProductItem from "../Components/ProductItem";

function ProductListPage(props) {
  const { navActions } = props;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [category, setCategory] = React.useState(() => {
    const selectedCat = getCategoryFromLocation({
      location: props.location,
      rootNode: categoryTreeRoot,
    });
    return selectedCat || categoryTreeRoot;
  });
  const [sort, setSort] = React.useState("+created");
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const _goToCategory = c => {
    setCategory(c);
    setShowDrawer(false);
    navActions.push(`/products`);
  };

  React.useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        setProducts(
          await Catalog.Actions.fetchProducts({
            category,
            sort,
          })
        );
      } catch (ex) {
        //bypass
      }
      setLoading(false);
    }

    fetchData();
  }, [category, sort]);

  return (
    <Wrapper>
      <div className="center-content">
        <Ant.Row>
          <CategoryTree
            color={Theme.colors.main}
            goToCategory={_goToCategory}
            rootNode={categoryTreeRoot}
          />
          <Ant.Col
            xs={24}
            sm={24}
            md={24}
            lg={19}
            style={{ padding: "30px 20px" }}
          >
            <div className="feature-bar">
              <FeatureBar
                sort={sort}
                toSort={setSort}
                color={Theme.colors.main}
              />
            </div>

            <CategoryBreadcrumb
              key={category.name}
              node={category}
              rootNode={categoryTreeRoot}
              goToCategory={_goToCategory}
              extraStyle={{ marginBottom: 20, marginTop: 10 }}
            />

            {loading && (
              <Ant.Spin style={{ display: "block", margin: "0 auto" }} />
            )}

            {!loading && (
              <div className="products-grid">
                {products.map((p, idx) => {
                  return (
                    <ProductItem
                      key={idx}
                      product={p}
                      onClick={() => {
                        navActions.push(`/product?id=${p.id}`);
                      }}
                    />
                  );
                })}

                {/* compensate blank item for ui: justfy-content: space-between */}
                {[0, 1, 2].map(e => (
                  <ProductItem key={e} product={null} />
                ))}
              </div>
            )}
          </Ant.Col>
        </Ant.Row>
      </div>

      {/* RWD mobile */}
      <Widget.ToggleDrawer onClick={() => setShowDrawer(true)}>
        <Icon.ListAlt size={24} color="white" />
      </Widget.ToggleDrawer>

      <Ant.Drawer
        title="全部商品"
        placement="left"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
      >
        <CategoryMenuItem
          node={categoryTreeRoot}
          goToCategory={_goToCategory}
        />
      </Ant.Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;

  & .center-content {
    max-width: ${Theme.pageContentWidth};
    margin: 20px auto;
  }

  & .feature-bar {
    display: flex;
    justify-content: flex-end;
  }

  & .products-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 520px) {
      justify-content: center;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(ProductListPage)
);
