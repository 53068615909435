import React from "react";

function catWalk(node, parent, result) {
  node.parent = parent;
  result.push(node);

  for (let c of node.children) {
    catWalk(c, node, result);
  }
}

class CategoryBreadcrumb extends React.Component {
  constructor(props) {
    super(props);

    const categoryTreeWithParent = [];

    catWalk(
      JSON.parse(JSON.stringify(props.rootNode)),
      null,
      categoryTreeWithParent
    );

    let node = categoryTreeWithParent.find(
      cat => cat.name === this.props.node.name
    );

    if (!node) {
      throw `CategoryBrowserBar: no such category with name ${this.props.node.name}`;
    }

    this.catBacktrace = [node];
    while (node.parent) {
      this.catBacktrace.push(node.parent);
      node = node.parent;
    }
    this.catBacktrace.reverse();
  }

  render() {
    const { extraStyle = {} } = this.props;

    return (
      <div style={{ display: "flex", ...extraStyle }}>
        {this.catBacktrace.map((cat, idx) => {
          const last = idx === this.catBacktrace.length - 1;
          return (
            <>
              <div
                style={{
                  fontSize: 10,
                  marginRight: 10,
                  flexGrow: 0,
                  color: last ? "black" : "grey",
                }}
                onClick={() => this._onLinkClick(cat)}
              >
                {`${cat.display}`}
              </div>
              {!last && (
                <div
                  style={{
                    fontSize: 10,
                    marginRight: 10,
                    flexGrow: 0,
                    color: "#ccc",
                  }}
                >
                  {`/`}
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  }

  _onLinkClick = cat => {
    const { goToCategory } = this.props;
    goToCategory && goToCategory(cat);
  };
}

export default CategoryBreadcrumb;
