import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import CategoryMenuItem from "../Components/CategoryMenuItem";

function CategoryTree(props) {
  const { color, rootNode, goToCategory } = props;

  return (
    <Ant.Col
      xs={0}
      sm={0}
      md={0}
      lg={5}
      style={{
        minHeight: "calc(100vh - 60px)",
        padding: "30px 10px",
      }}
    >
      <Title color={color}>全部商品</Title>
      <CategoryMenuItem node={rootNode} goToCategory={goToCategory} />
    </Ant.Col>
  );
}

const Title = styled.p`
  color: ${props => props.color};
  margin-top: 10px;
  font-size: 18px;
`;

export default CategoryTree;
