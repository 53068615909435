const queryString = require("query-string");

export default function getCategoryFromLocation({ location, rootNode }) {
  function findCat(catName, node) {
    if (node.name === catName) {
      return node;
    }

    for (let child of node.children) {
      const hit = findCat(catName, child);
      if (hit) {
        return hit;
      }
    }

    return null;
  }

  try {
    const catName = queryString.parse(location.search).name;

    if (!catName) {
      return null;
    }

    return findCat(catName, rootNode);
  } catch (ex) {
    return null;
  }
}
