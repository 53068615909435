import React from "react";
import * as Ant from "antd";

function FeatureBar(props) {
  let { sort, toSort, color = "pink" } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Ant.Select
        value={sort}
        style={{
          width: 160,
          fontSize: 12,
          borderColor: color,
        }}
        onChange={sort => toSort(sort)}
      >
        <Ant.Select.Option value="+created" style={{ fontSize: 12 }}>
          依日期排列(由舊到新)
        </Ant.Select.Option>
        <Ant.Select.Option value="-created" style={{ fontSize: 12 }}>
          依日期排列(由新到舊)
        </Ant.Select.Option>
        <Ant.Select.Option value="+price" style={{ fontSize: 12 }}>
          依價位排列(由低到高)
        </Ant.Select.Option>
        <Ant.Select.Option value="-price" style={{ fontSize: 12 }}>
          依價位排列(由高到低)
        </Ant.Select.Option>
      </Ant.Select>
    </div>
  );
}

export default FeatureBar;
