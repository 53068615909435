import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import * as Theme from "../Theme";
import Image from "./ImageBackground";
import * as Icon from "./Icon";
import TeacherData from "./TeacherData";
import { getLabels, CATEGORY_COLORS } from "../Utils/ProductUtil";

const width = 240;

class ProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
    };
  }

  render() {
    const { product, deadline, extraCss = "" } = this.props;
    const { showSpinner } = this.state;
    if (!product) {
      return <Wrapper css={extraCss + "opacity: 0; height: 0px;"}></Wrapper>;
    }

    return (
      <Wrapper onClick={this._onClick} css={extraCss}>
        {(product.image && (
          <ImageWrapper>
            <Image
              src={product.image}
              extraStyle={{
                backgroundColor: "#eee",
                position: "absolute",
                margin: 0,
              }}
            />
          </ImageWrapper>
        )) || (
          <ImageWrapper>
            <MissingImage>
              <Icon.BrokenImage color="#aaa" size="50" />
            </MissingImage>
          </ImageWrapper>
        )}

        <Content style={{ flex: 1 }}>
          <ProductName style={{ flexGrow: 1 }}>{product.name}</ProductName>
          <div style={{ flex: 1 }} />
          <TeacherData teacher={product.teacher} />

          <Info>
            <p>課程總時數：{product.total_time} 分鐘</p>
            <p>課程總節數：{product.video_count} 節</p>
            {deadline && <p>課程到期日：{deadline.slice(0, 10)}</p>}
          </Info>

          <Price style={{ flexShrink: 0 }}>
            NT ${product.type === "free" ? 0 : product.price}
          </Price>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            {getLabels(product)
              .slice(0, 3)
              .map(x => (
                <Ant.Tag
                  color={CATEGORY_COLORS[x]}
                  key={x}
                  style={{ marginBottom: 3 }}
                >
                  {" "}
                  {x}{" "}
                </Ant.Tag>
              ))}
            {getLabels(product).length > 3 && "..."}
          </div>
        </Content>

        {showSpinner && (
          <LoadingDetail>
            <Ant.Spin style={{ display: "block" }} />
          </LoadingDetail>
        )}
      </Wrapper>
    );
  }

  _onClick = () => {
    // TODO: once the performance is better, remove this
    this.setState({ showSpinner: true });
    this.props.onClick();
  };
}

const LoadingDetail = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const Image = styled.figure`
//   background-color: #e0e0e0;
//   background-image: url(${props => props.url});
//   background-size: cover;
//   background-position: center;
//   width: 100%;
//   height: 198px;
//   margin: 0;
// `;

const ImageWrapper = styled.div`
  width: 100%;
  padding-bottom: ${160 / Theme.ratio}%;
  position: relative;
`;

const MissingImage = styled.figure`
  background-color: #e0e0e0;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  padding: 15px;
`;

const Info = styled.div`
  margin-top: 10px;
  & > p {
    margin: 0px 0px 0px 0px;
    font-weight: 300;
  }
`;

const ProductName = styled.div`
  font-size: 18px;
  color: ${Theme.colors.main};
  margin-bottom: 6px;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 4.4em;
`;

const OriginalPrice = styled.div`
  color: #aaa;
  text-decoration: line-through;
  text-align: center;
  font-size: 10px;
`;

const Price = styled.div`
  text-align: right;
  font-weight: 300;
  letter-spacing: 1px;
  color: ${Theme.colors.main};
  font-size: 20px;
`;

const Tag = styled.div`
  padding: 2px 8px;
  margin: 0 4px 5px 0;
  font-size: 10px;
  color: white;
  border-radius: 8px;
  background-color: #eb5554;
  ${props => props.css || ""}
`;

const Wrapper = styled.div`
  width: ${width}px;
  height: ${width * 2}px;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #ddd;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s;
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 1px 10px rgba(136, 136, 136, 0.3);
  }
  ${props => props.css}
`;

export default ProductItem;
