import React from "react";
import styled from "styled-components";
import * as Ant from "antd";
import * as Theme from "../Theme";
import Image from "./ImageBackground";
import * as Icon from "./Icon";

class TeacherData extends React.Component {
  render() {
    let { teacher } = this.props;
    return (
      <Wrapper>
        <Image
          src={teacher.image}
          extraStyle={{
            width: 54,
            height: 54,
            borderRadius: 27,
            backgroundColor: "#eee",
            margin: 0,
          }}
        />
        <p>{teacher.name}</p>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: 0px 0px 0px 10px;
    font-weight: 300;
  }
`;

export default TeacherData;
